/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { appState, aws, constants } from '@aim/common';
import {
  styled,
  theme,
  AimDialog,
  AimAutocomplete,
  CustomIntl,
  AimTextField,
  AimTypography,
} from '@aim/components';
import { navigateToUrl } from 'single-spa';

const adminUserTypes = [constants.UserTypes.ADMIN.id];

const CustomPaperComponent = styled(Paper)({
  background: 'transparent !important',
  boxShadow: '0 0 black !important',
  width: appState.mainContainerZoom.getValue().width,
  height: appState.mainContainerZoom.getValue().height,
});

const usersQuery = `
query Users {
  listUsers {
    items {
      email
      familyName
      givenName
      id
      type
      cognitoUserId
    }
  }
}
`;

const participationsQuery = `
query ParticipantsByEventId($participationEventId: ID) {
  participantByEventId(
    participationEventId: $participationEventId
    filter: { isDeleted: { ne: true }, cognitoUserId: {attributeExists: true}} 
  ) {
    items {
      id
      username
      email
      givenName
      familyName
      cognitoUserId
      cluster
      agency {
        id
      }
      sponsor {
        id
      }
    }
  } 
}`;

const formControlStyle = { width: 'calc(100% - 10px)' };

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          style={{ color: 'white' }}
        >
          {props.value ? `${Math.round(props.value)}%` : ''}
        </Typography>
      </Box>
    </Box>
  );
}

const CustomDialog = styled(Dialog)(({ containerSize }) => ({
  boxShadow: '0 0 transparent !important',
  transform: `scale(${appState.mainContainerZoom.getValue()})`,
  transformOrigin: 'top left',
  width: containerSize.width,
  height: containerSize.height,
}));

export default function App({ appName }) {
  const [users, setUsers] = useState([]);
  const intl = CustomIntl(useIntl());
  const [branch, setBranch] = useState();
  const [selectedUser, setSeletedUser] = useState();
  const [eventConfiguration, setEventConfiguration] = useState();
  const [openCounter, setOpenCounter] = useState(0);
  const [value, setValue] = useState(0);
  const [impersonateDialogOpen, setImpersonateDialogOpen] = useState(false);
  const [branchDialogOpen, setBranchDialogOpen] = useState(false);

  const [user, setUser] = useState();

  useEffect(() => {
    const branch = localStorage.getItem(`aim-branch`);
    if (branch) {
      setBranch(JSON.parse(branch));
    }
  }, []);

  const agencyString = intl.formatMessage({
    description: 'agency',
    defaultMessage: 'Agency',
  });
  const sponsorString = intl.formatMessage({
    description: 'sponsor',
    defaultMessage: 'Sponsor',
  });

  useEffect(() => {
    const userSubscription = appState.user.subscribe(setUser);
    const loaderValueSubscription = appState.loaderValue.subscribe(setValue);
    const configSubscription = appState.eventConfiguration.subscribe(
      setEventConfiguration
    );
    const loaderSubscription = appState.isLoader.subscribe((value) =>
      value
        ? setOpenCounter((counter) => counter + 1)
        : setOpenCounter((counter) => (counter > 0 ? counter - 1 : counter))
    );

    return () => {
      userSubscription.unsubscribe();
      loaderSubscription.unsubscribe();
      configSubscription.unsubscribe();
      loaderValueSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!openCounter && value) {
      setValue(0);
    }
  }, [openCounter]);

  useEffect(() => {
    const listener = async (event) => {
      if (event.altKey && event.keyCode === 73) {
        console.log('impersonate', appState.user.getValue()?.originalUser);
        if (
          appState.user.getValue()?.awsUser?.groups.includes('admin') &&
          (appName === 'aim-root-config'
            ? adminUserTypes.includes(appState.user.getValue()?.type) ||
              adminUserTypes.includes(
                appState.user.getValue()?.originalUser?.type
              )
            : true)
        ) {
          setImpersonateDialogOpen((value) => !value);

          appName === 'aim-root-config'
            ? await listUsers()
            : await listParticipations();
        }
      } else if (event.altKey && event.keyCode === 85) {
        localStorage.removeItem(`impersonate-user-${appName}`);
        window.location.reload();
      } else if (event.altKey && event.keyCode === 76) {
        await aws.signOut();
        localStorage.removeItem(`impersonate-user-${appName}`);
        appName === 'aim-root-config' && navigateToUrl('/login');
      } else if (event.altKey && event.keyCode === 66) {
        if (branch) {
          localStorage.removeItem(`aim-branch`);
          Object.entries(
            JSON.parse(
              window.document.getElementById('importmap-aim-script').innerHTML
            ).imports
          )
            .filter(([key]) => key.startsWith('@aim/'))
            .forEach(([key]) =>
              localStorage.removeItem(`import-map-override:${key}`)
            );
          window.location.reload();
        } else {
          setBranchDialogOpen(true);
        }
      }
    };

    const listUsers = async () => {
      const awsUser = await aws.getAwsUser();
      if (awsUser) {
        aws.API.graphql({
          query: usersQuery,
        })
          .then((response) => {
            const nextUsers = response.data.listUsers.items.map((u) => ({
              ...u,
              label: `${u.givenName} ${u.familyName} <${u.email}>`,
            }));
            setUsers(nextUsers);
          })
          .catch((e) => {
            console.error('get-users', e);
          });
      }
    };

    const listParticipations = async () => {
      aws.API.graphql({
        query: participationsQuery,
        variables: {
          participationEventId: appState.eventInfo.getValue()?.id,
        },
      })
        .then((response) => {
          const nextUsers = response.data.participantByEventId.items.map(
            (p) => ({
              ...p,
              label: `${p.username + ' ' || ''}${p.givenName} ${
                p.familyName
              } <${p.email}> ${
                p.agency
                  ? `${agencyString} `
                  : p.sponsor
                  ? `${sponsorString} `
                  : ''
              }${
                Object.values(constants.Clusters)
                  .find((c) => c.id === p.cluster)
                  ?.label(intl) || ''
              }`,
            })
          );
          setUsers(nextUsers);
        })
        .catch((e) => {
          console.error('get-participations', e);
        });
    };

    document.addEventListener('keyup', listener);

    return () => {
      document.removeEventListener('keyup', listener);
    };
  }, [user]);

  const storageBranch = localStorage.getItem(`aim-branch`)
    ? JSON.parse(localStorage.getItem(`aim-branch`))
    : null;

  return (
    <>
      {storageBranch ? (
        <div
          style={{
            zIndex: 1000,
            position: 'absolute',
            background: 'red',
            transform: 'rotate(-36deg)',
            top: 30,
            left: -40,
            width: 220,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <AimTypography
            ellipsis
            style={{
              color: 'white',
              textTransform: 'uppercase',
              fontWeight: 'bold',
              maxWidth: 180,
            }}
          >
            {storageBranch.replace('feature/', '')}
          </AimTypography>
        </div>
      ) : null}
      <AimDialog
        title="Select user"
        open={impersonateDialogOpen}
        onClose={() => setImpersonateDialogOpen(false)}
        onAgree={() => {
          localStorage.setItem(
            `impersonate-user-${appName}`,
            JSON.stringify(selectedUser)
          );
          setImpersonateDialogOpen(false);
          window.location.reload();
        }}
        fullWidth
        maxWidth="lg"
      >
        <AimAutocomplete
          options={users || []}
          getOptionLabel={(opt) => {
            return opt.label;
          }}
          onChange={(_, value) => setSeletedUser(value)}
          formControlStyle={formControlStyle}
        />
      </AimDialog>
      <AimDialog
        title="Switch Branch"
        open={branchDialogOpen}
        onClose={() => setBranchDialogOpen(false)}
        onAgree={() => {
          Object.entries(
            JSON.parse(
              window.document.getElementById('importmap-aim-script').innerHTML
            ).imports
          )
            .filter(([key]) => key.startsWith('@aim/'))
            .forEach(([key, value]) =>
              localStorage.setItem(
                `import-map-override:${key}`,
                value.replace(
                  `https://${process.env.AWS_BRANCH}.`,
                  `https://${branch}.`
                )
              )
            );

          localStorage.setItem(`aim-branch`, JSON.stringify(branch));
          setBranchDialogOpen(false);
          window.location.reload();
        }}
        fullWidth
        maxWidth="lg"
      >
        <AimTextField
          value={branch}
          onChange={(e) => setBranch(e.target.value)}
          formControlStyle={formControlStyle}
        />
      </AimDialog>
      <CustomDialog
        open={openCounter}
        PaperComponent={CustomPaperComponent}
        containerSize={appState.mainContainerSize.getValue()}
      >
        <DialogContent>
          <CircularProgressWithLabel
            style={{
              color:
                appName !== 'aim-root-config' && eventConfiguration
                  ? eventConfiguration?.primaryColor
                  : theme.colors.primary.yellow,
            }}
            value={value}
          />
        </DialogContent>
      </CustomDialog>
    </>
  );
}
